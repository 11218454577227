<template>
  <TaskDatePickerPopover
    v-model:open="open"
    :start-date="task.startDate"
    :due-date="task.dueDate"
    class="w-full h-full"
    :disabled="disabled"
    :ui="{
      trigger: 'h-full items-center',
    }"
    @click.exact.stop.prevent
    @update:open="onUpdateOpen"
    @change="handleDateChange"
  >
    <div :class="['w-full h-full', { 'active': open }, cellClass]">
      <UButton
        size="2xs"
        variant="soft"
        color="white"
        class="text-gray-900 w-full h-full py-0"
        :disabled="disabled"
        @click="emit('click')"
      >
        <TaskDatesFormat
          v-if="task.startDate || task.dueDate"
          class="text-start leading-4 whitespace-pre-wrap text-wrap flex flex-wrap items-center h-full font-normal"
          :start="task.startDate"
          :end="task.dueDate"
          :format="{
            time: ''
          }"
        />
        <UButton
          v-else
          variant="ghost"
          color="white"
          class="rounded-full w-fit px-0 hover:bg-transparent"
          icon="i-heroicons-calendar"
          size="2xs"
          :disabled="disabled"
        />
      </UButton>
    </div>
  </TaskDatePickerPopover>
</template>

<script lang="ts" setup>
import type { FieldCallback } from '#field/types'
import type { TaskItem } from '#task/types'

const props = defineProps({
  task: {
    type: Object as PropType<TaskItem>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  cellClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'click'): void
  (e: 'update:open', value: boolean): void
}>()

const { selectedTaskIds } = useListViewSharedState()
const { updateTask, updateTasks } = useUpdateTask()

const open = ref(false)

const handleDateChange = (dates: Pick<TaskItem, 'startDate' | 'dueDate'>) => {
  if (selectedTaskIds.value.has(props.task.id)) {
    return updateTasks(Array.from(selectedTaskIds.value), dates)
  }

  updateTask(props.task.id, dates)
}

const handleOpen = () => {
  open.value = true
}

const onUpdateOpen = (value: boolean) => {
  open.value = value
  emit('update:open', value)
}

onMounted(() => {
  emit('set-callback', {
    open: handleOpen,
    delete: () => {
      handleDateChange({ startDate: '', dueDate: '' })
    }
  })
})

defineExpose({
  focus: () => handleOpen,
})
</script>
